:root {
  --color-text-action: rgb(63, 62, 62);
  --color-text-action-card: rgb(255, 255, 255);
  /*main bg color */
  --color-fill: #141133;
  /* loader */
  --color-fill-loader: #aa1c1c;
  --color-fill-loader-bg: rgb(159, 159, 159);
  /* text color */
  --color-text-base: #ffffff;
  /* text hover        */
  --color-text-base-hover: #aa1c1c;
  /* active nav  */
  --color-text-active-nav: #ffffff;
  --color-text-active-subnav: #2196f3;
  /* border color  */
  --color-border-color: #ffffff;
  /* popup bg color  */
  --color-fill-popup: #141133;
  /* left nav profile color  */
  --color-fill-profile: #302a71;
  --color-fill-profile-icon: rgb(35, 35, 35);
  --color-fill-profile-btn-hover: #2d2772;
  /* card bg color  */
  --color-fill-card: #2f2877;
  /* form header  */
  --color-fill-formheader: #1d1951;
  /* outlet bg color  */
  --color-fill-outlet: #f6f6f6;
  /* icons bg color  */
  --color-fill-iconBg: #141133;
  /* drag & drop input bg color  */
  --color-fill-dragInput: #f2eaea;
  /* nav controller */
  --color-fill-navController-bg: #292467;
  --color-fill-navController-bg-hover: #141133;
  --color-text-navController-text: #ffffff;
  /* table*/
  --color-text-base-table-hover: #050505;
  --color-fill-table: rgb(255, 250, 250);
  --color-fill-table-head: rgb(219, 219, 219);
  --color-fill-hover: #eaeaea;
  --color-border-table-color: #949494;
  --color-text-table-head-text: #353535;
  --color-text-table-body-text: #4b4b4b;
  /* button*/
  --color-button-bgcolor: #141133;
  --color-text-button-text: #ffffff;
  --color-button-hover: #ed4a8ba9;
  --color-button-muted: #1e1f58;
  /* notification */
  --color-fill-notification: rgb(255, 255, 255);
  --color-fill-notification-text: #000000;
  --color-text-notification-allButton-text: #3f51b5;
  --color-fill-notification-allButton-hover: rgb(186, 186, 186);
  --color-fill-notification-unreadButton: rgb(255, 253, 253);
  --color-fill-notification-unreadButton-hover: #9fa8da;
  --color-fill-notification-hover: rgb(211, 211, 211);
  --color-text-notification-secondary-text: #1f1f1f;
  /* navbar  */
  --color-fill-nav-bg: #2196f3;
  --color-fill-nav-text: #ffffff;
  --color-fill-nav-text-hover: #1976d2;
  --color-fill-nav-hover: #90caf9;
  /* topnavButton  */
  --color-fill-Topnav-button: #2196f3;
  --color-fill-Topnav-button-text: #ffffff;
  /* companyAsset  */
  --color-fill-company-card-bg: #455bac;
  --color-fill-company-table-bg: #ffffff;
  --color-fill-company-icon-bg: #e5e5e5;
  --color-fill-company-card-text: #ffffff;
  --color-fill-company-mainText: #000000;
  --color-fill-company-taible-text: #2f2f2f;

  /* pagination */
  --color-fill-paginatioin-text: #bdc1d1;
  --color-fill-paginatioin-border: #0e258b;
  --color-fill-paginatioin-active: #1d1951;


  /* form */
  --color-fill-lable: #aa1c1c;

}