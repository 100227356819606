.theme1 {
  --color-fill: #7f95ff;
  --color-text-base: #ffff;
  --color-fill-loader: rgb(0, 0, 0);
  --color-fill-loader-bg: #7181ce;
  --color-text-base-hover: #000000;
  --color-text-active-nav: #ffffff;
  --color-text-active-subnav: #000000;
  --color-border-color: #ffffff;
  --color-fill-popup: #7f95ff;
  --color-fill-profile: #94a5f8;
  --color-fill-profile-icon: #a6b4f5;
  --color-fill-iconBg: #7f95ff;
  --color-fill-card: #a7b5f7;
  --color-fill-dragInput: #9daaf3;
  --color-fill-formheader: rgb(131, 153, 220);
  --color-fill-outlet: #f4f4f5;
  --color-fill-profile-btn-hover: #a9adc5;
  --color-text-action: #081654;
  --color-text-action-card: rgb(255, 255, 255);
  /* nav controller */
  --color-fill-navController-bg: #a2b1ff;
  --color-fill-navController-bg-hover: #7f95ff;
  --color-text-navController-text: rgb(255, 255, 255);
  /* table  */
  --color-fill-table-head: #7f95ff;
  --color-text-table-head-text: #030a26;
  --color-text-table-body-text: #081654;
  --color-text-base-table-hover: #110083;
  --color-fill-table: #c5cae9;
  --color-border-table-color: #2206c1;
  --color-fill-hover: #a9adc5;
  /* button  */
  --color-button-bgcolor: #7f95ff;
  --color-text-button-text: #ffffff;
  --color-button-hover: #3d40ed;
  --color-button-muted: #5c5ea8;
  /* notification */
  --color-fill-notification: #5c6bc0;
  --color-fill-notification-text: #ffff;
  --color-fill-notification-allButton: #673ab7;
  --color-text-notification-allButton-text: white;
  --color-fill-notification-allButton-hover: #5c6bc0;
  --color-fill-notification-unreadButton: #5c6bc0;
  --color-fill-notification-unreadButton-hover: #673ab7;
  --color-fill-notification-hover: #7183ec;
  --color-text-notification-secondary-text: #fafafa;
  /* navbar  */
  --color-fill-nav-bg: #3d5dff;
  --color-fill-nav-text: rgb(255, 255, 255);
  --color-fill-nav-text-hover: rgb(204, 204, 204);
  --color-fill-nav-hover: #516eff;
  /* topnavButton  */
  --color-fill-Topnav-button: #3d5dff;
  --color-fill-Topnav-button-text: #ffffff;
  /* companyAsset  */
  --color-fill-company-card-bg: #788ce2;
  --color-fill-company-table-bg: #ffffff;
  --color-fill-company-icon-bg: #e5e5e5;
  --color-fill-company-card-text: #ffffff;
  --color-fill-company-mainText: #000000;
  --color-fill-company-taible-text: #282828;
  /* pagination */
  --color-fill-paginatioin-text: #bdc1d1;
  --color-fill-paginatioin-border: #1833ac;
  --color-fill-paginatioin-active: #7f95ff;
  /* form */
  --color-fill-lable: #aa1c1c;

}