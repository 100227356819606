.theme3 {
  --color-fill: #94A684;
  --color-text-base: #ffffff;
  --color-text-action: #050404;
  --color-text-action-card: rgb(255, 255, 255);
  --color-text-active-nav: #ffffff;
  --color-text-active-subnav: #000000;
  --color-text-base-hover: #000000;
  --color-fill-loader: rgb(255, 255, 255);
  --color-fill-loader-bg: #94A684;
  ;
  --color-border-color: #ffffff;
  --color-fill-popup: #94A684;
  ;
  --color-fill-profile: #a6b598;
  --color-fill-profile-icon: #b9d1a3;
  --color-fill-card: #cfe4bf;
  --color-fill-iconBg: #94A684;
  --color-fill-dragInput: #b0bba7;
  --color-fill-formheader: #94A684;
  --color-fill-outlet: #f7fff0;
  --color-fill-profile-btn-hover: #778968;
  /* nav controller */
  --color-fill-navController-bg: #a9b79d;
  --color-fill-navController-bg-hover: #94A684;
  --color-text-navController-text: rgb(0, 0, 0);
  /* table  */
  --color-fill-table-head: #a1c885;
  --color-fill-hover: #d9f0c5;
  --color-text-table-head-text: #000000;
  --color-text-table-body-text: #050404;
  --color-text-base-table-hover: #2d5c03;
  --color-fill-table: #c9dfb6;
  --color-border-table-color: #84a765;
  /* button  */
  --color-button-bgcolor: #94A684;
  --color-text-button-text: #ffff;
  --color-button-hover: #94A684;
  --color-button-muted: #969e8e;
  /* notification */
  --color-fill-notification: #8aa175;
  --color-fill-notification-text: #ffff;
  --color-fill-notification-allButton: #81c784;
  --color-text-notification-allButton-text: white;
  --color-fill-notification-allButton-hover: #8aa175;
  --color-fill-notification-unreadButton: #8aa175;
  --color-fill-notification-unreadButton-hover: #81c784;
  --color-fill-notification-hover: #9db785;
  --color-text-notification-secondary-text: #fafafa;
  /* navbar  */
  --color-fill-nav-bg: #69765e;
  --color-fill-nav-text: rgb(255, 255, 255);
  --color-fill-nav-text-hover: rgb(255, 255, 255);
  --color-fill-nav-hover: #7f8f72;
  /* topnavButton  */
  --color-fill-Topnav-button: #69765e;
  --color-fill-Topnav-button-text: #ffffff;
  /* companyAsset  */
  --color-fill-company-card-bg: #668a7c;
  --color-fill-company-table-bg: #ffffff;
  --color-fill-company-icon-bg: #e5e5e5;
  --color-fill-company-card-text: #ffffff;
  --color-fill-company-mainText: #000000;
  --color-fill-company-taible-text: #282828;

  /* pagination */
  --color-fill-paginatioin-text: #bdc1d1;
  --color-fill-paginatioin-border: #1833ac;
  --color-fill-paginatioin-active: #69765e;
  /* form */
  --color-fill-lable: #aa1c1c;
}