@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @import url('./Themes/Default.css');
    @import url('./Themes/Theme1.css');
    @import url('./Themes/Theme2.css');
    @import url('./Themes/Theme3.css');
    @import url('./Themes/Theme4.css');
    @import url('./Themes/Theme5.css');
}

.stepper-arrow {
    clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0% 100%, 7% 50%, 0% 0%);
}

.stepper-arrow:first-child {
    clip-path: polygon(93% 0, 100% 50%, 93% 100%, 0% 100%, 0% 50%, 0% 0%);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

* {
    font-family: "Work Sans", Sans-serif;
}