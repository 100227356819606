.theme5 {
  /*main bg color */
  --color-fill: #000000;
  /* loader */
  --color-fill-loader: #ffffff;
  --color-fill-loader-bg: rgb(0, 0, 0);
  /* text color */
  --color-text-base: #ffffff;
  --color-text-action: #616161;
  --color-text-action-card: rgb(255, 255, 255);
  /* text hover        */
  --color-text-base-hover: #ffb089;
  /* active nav  */
  --color-text-active-nav: #ff915b;
  --color-text-active-subnav: #ffb089;
  /* border color  */
  --color-border-color: #ffffff;
  /* popup bg color  */
  --color-fill-popup: #000000;
  /* left nave profile color  */
  --color-fill-profile: rgb(70, 69, 69);
  --color-fill-profile-icon: rgb(0, 0, 0);
  --color-fill-profile-btn-hover: #414141;
  /* card bg color  */
  --color-fill-card: rgb(36, 33, 33);
  /* form header  */
  --color-fill-formheader: rgb(54, 54, 54);
  /* outlet bg color  */
  --color-fill-outlet: #ffffff;
  /* icons bg color  */
  --color-fill-iconBg: rgb(0, 0, 0);
  /* drag & drop input bg color  */
  --color-fill-dragInput: #000000;
  /* nav controller */
  --color-fill-navController-bg: rgb(56, 53, 53);
  --color-fill-navController-bg-hover: rgb(0, 0, 0);
  --color-text-navController-text: rgb(255, 255, 255);
  /* table*/
  --color-text-base-table-hover: #ffffff;
  --color-fill-table: rgb(32, 32, 32);
  --color-fill-table-head: rgb(0, 0, 0);
  --color-fill-hover: #414141;
  --color-border-table-color: #000000;
  --color-text-table-head-text: #ffffff;
  --color-text-table-body-text: #ebebeb;
  /* button*/
  --color-button-bgcolor: #000000;
  --color-text-button-text: #ffffff;
  --color-button-hover: #000000a9;
  --color-button-muted: #000000;
  /* notification */
  --color-fill-notification: #000000;
  --color-fill-notification-text: #ffffff;
  --color-fill-notification-allButton: #b39ddb;
  --color-text-notification-allButton-text: #512da8;
  --color-fill-notification-allButton-hover: #000000;
  --color-fill-notification-unreadButton: #000000;
  --color-fill-notification-unreadButton-hover: #b39ddb;
  --color-fill-notification-hover: #242323;
  --color-text-notification-secondary-text: #fafafa;
  /* navbar  */
  --color-fill-nav-bg: #252525;
  --color-fill-nav-text: rgb(255, 255, 255);
  --color-fill-nav-text-hover: rgb(255, 255, 255);
  --color-fill-nav-hover: #383838;
  /* topnavButton  */
  --color-fill-Topnav-button: #252525;
  --color-fill-Topnav-button-text: #ffffff;
  /* companyAsset  */
  --color-fill-company-card-bg: #595f65;
  --color-fill-company-table-bg: #ffffff;
  --color-fill-company-icon-bg: #e5e5e5;
  --color-fill-company-card-text: #ffffff;
  --color-fill-company-mainText: #000000;
  --color-fill-company-taible-text: #282828;
  /* pagination */
  --color-fill-paginatioin-text: #bdc1d1;
  --color-fill-paginatioin-border: #1833ac;
  --color-fill-paginatioin-active: #252525;
  /* form */
  --color-fill-lable: #aa1c1c;
}