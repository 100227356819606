.theme2 {
  --color-fill: #2C74B3;
  --color-text-active-nav: #ffffff;
  --color-text-active-subnav: #000000;
  --color-text-base: rgb(255, 255, 255);
  --color-text-action: #062270;
  --color-text-action-card: rgb(255, 255, 255);
  --color-fill-loader: #e6eaed;
  --color-fill-loader-bg: #4a9be3;
  --color-text-base-hover: rgb(0, 0, 0);
  --color-border-color: #ffffff;
  --color-fill-popup: #2C74B3;
  --color-fill-profile: #487da9;
  --color-fill-profile-icon: #5eabee;
  --color-fill-iconBg: #2C74B3;
  --color-fill-card: #69b4f5;
  --color-fill-dragInput: #64b5f6;
  --color-fill-formheader: #2C74B3;
  --color-fill-outlet: #f4f4f5;
  --color-fill-profile-btn-hover: #39a0fb;
  /* nav controller */
  --color-fill-navController-bg: #378cd7;
  --color-fill-navController-bg-hover: #2C74B3;
  --color-text-navController-text: rgb(255, 255, 255);
  /* table  */
  --color-fill-table-head: #0b6eca;
  --color-fill-hover: #39a0fb;
  --color-text-table-head-text: #020e1f;
  --color-text-table-body-text: #062270;
  --color-text-base-table-hover: #143ba7;
  --color-border-table-color: #010b53;
  --color-fill-table: #4398e3;
  /* button */
  --color-button-bgcolor: #2C74B3;
  --color-text-button-text: #ffff;
  --color-button-hover: #2C74B3;
  --color-button-muted: #2C74B3;
  /* notification */
  --color-fill-notification: #2C74B3;
  --color-fill-notification-text: #ffff;
  --color-fill-notification-allButton: #6d4c41;
  --color-text-notification-allButton-text: white;
  --color-fill-notification-allButton-hover: #2C74B3;
  --color-fill-notification-unreadButton: #2C74B3;
  --color-fill-notification-unreadButton-hover: #6d4c41;
  --color-fill-notification-hover: #358ad4;
  --color-text-notification-secondary-text: #fafafa;
  /* navbar  */
  --color-fill-nav-bg: #01559e;
  --color-fill-nav-text: rgb(255, 255, 255);
  --color-fill-nav-text-hover: rgb(236, 236, 236);
  --color-fill-nav-hover: #046bc5;
  /* topnavButton  */
  --color-fill-Topnav-button: #01559e;
  --color-fill-Topnav-button-text: #ffffff;
  /* companyAsset  */
  --color-fill-company-card-bg: #2196f3;
  --color-fill-company-table-bg: #ffffff;
  --color-fill-company-icon-bg: #e5e5e5;
  --color-fill-company-card-text: #ffffff;
  --color-fill-company-mainText: #000000;
  --color-fill-company-taible-text: #282828;
  /* pagination */
  --color-fill-paginatioin-text: #bdc1d1;
  --color-fill-paginatioin-border: #1833ac;
  --color-fill-paginatioin-active: #01559e;
  /* form */
  --color-fill-lable: #aa1c1c;
}