.theme4 {
  /*main bg color */
  --color-fill: rgb(243, 243, 243);
  /* loader */
  --color-fill-loader: #aa1c1c;
  --color-fill-loader-bg: rgb(243, 243, 243);
  /* text color */
  --color-text-base: rgb(48, 48, 48);
  --color-text-action: #454545;
  --color-text-action-card: rgb(0, 0, 0);
  /* text hover        */
  --color-text-base-hover: #aa1c1c;
  /* border color  */
  --color-border-color: #000000;
  /* active nav  */
  --color-text-active-nav: #aa1c1c;
  --color-text-active-subnav: #390202;
  /* popup bg color  */
  --color-fill-popup: rgb(255, 255, 255);
  /* left nave profile color  */
  --color-fill-profile: rgb(235, 233, 227);
  --color-fill-profile-icon: rgb(208, 207, 201);
  --color-fill-profile-btn-hover: #e7e1e1;
  /* card bg color  */
  --color-fill-card: rgb(237, 237, 237);
  /* form header  */
  --color-fill-formheader: rgb(227, 224, 224);
  /* outlet bg color  */
  --color-fill-outlet: #fbfbff;
  /* icons bg color  */
  --color-fill-iconBg: rgb(243, 243, 243);
  /* drag & drop input bg color  */
  --color-fill-dragInput: #f0f0f0;
  /* nav controller */
  --color-fill-navController-bg: #f2eaea;
  --color-fill-navController-bg-hover: rgb(243, 243, 243);
  --color-text-navController-text: rgb(48, 48, 48);
  /* table*/
  --color-text-base-table-hover: #050505;
  --color-fill-table: rgb(255, 255, 255);
  --color-fill-table-head: rgb(238, 235, 235);
  --color-fill-hover: #e7e1e1;
  --color-border-table-color: rgb(121, 121, 121);
  --color-text-table-head-text: #474747;
  --color-text-table-body-text: #454545;
  /* button*/
  --color-button-bgcolor: rgb(214, 214, 214);
  --color-text-button-text: #000000;
  --color-button-hover: #ed4a8ba9;
  --color-button-muted: #c7c7c7;
  /* notification */
  --color-fill-notification: rgb(242, 242, 242);
  --color-fill-notification-text: rgb(48, 48, 48);
  --color-fill-notification-allButton: #f48fb1;
  --color-text-notification-allButton-text: black;
  --color-fill-notification-allButton-hover: rgb(243, 243, 243);
  --color-fill-notification-unreadButton: rgb(243, 243, 243);
  --color-fill-notification-unreadButton-hover: #f48fb1;
  --color-fill-notification-hover: rgb(255, 255, 255);
  --color-text-notification-secondary-text: #1f1f1f;
  /* navbar  */
  --color-fill-nav-bg: rgb(197, 197, 197);
  --color-fill-nav-text: rgb(0, 0, 0);
  --color-fill-nav-text-hover: rgb(29, 27, 27);
  --color-fill-nav-hover: rgb(214, 214, 214);
  /* topnavButton  */
  --color-fill-Topnav-button: rgb(197, 197, 197);
  --color-fill-Topnav-button-text: #000000;
  /* companyAsset  */
  --color-fill-company-card-bg: #dfe0e1;
  --color-fill-company-table-bg: #ffffff;
  --color-fill-company-icon-bg: #e5e5e5;
  --color-fill-company-card-text: #ffffff;
  --color-fill-company-mainText: #000000;
  --color-fill-company-taible-text: #282828;

  /* pagination */
  --color-fill-paginatioin-text: #bdc1d1;
  --color-fill-paginatioin-border: #1833ac;
  --color-fill-paginatioin-active: rgb(197, 197, 197);
  /* form */
  --color-fill-lable: #aa1c1c;
}